<script setup lang="ts">
import type { FunctionalComponent } from "vue";
import { VTextField } from "vuetify/components/VTextField";

const emit = defineEmits<{
  "update:modelValue": [string];
  "click:clear": [];
  "click:appendInner": [];
  "click:prependInner": [];
  blur: [];
  focused: [];
  click: [];
}>();

interface Props {
  name?: string | undefined;
  type?: string;
  modelValue: string | number | null | undefined;
  label?: string;
  placeholder?: string;
  hint?: string;
  suffix?: string;
  appendInnerIcon?: string | (new () => any) | FunctionalComponent;
  prependInnerIcon?: string | (new () => any) | FunctionalComponent;
  hideDetails?: boolean | "auto";
  color?: string;
  variant?: "underlined" | "outlined" | "filled" | "solo" | "plain";
  loading?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  persistentHint?: boolean;
  persistentPlaceholder?: boolean;
  counter?: string | number | true;
  clearable?: boolean;
  singleLine?: boolean;
  autocomplete?: string;
  rules?: ((v: string) => string | boolean)[];
  autofocus?: boolean;
  errorMessage?: string;
  density?: "default" | "comfortable" | "compact";
  inputmode?: string;
  onKeydown?: ((payload: KeyboardEvent) => void) | undefined;
  bgColor?: string;
}

const attrs = useAttrs();

const props = withDefaults(defineProps<Props>(), {
  type: undefined,
  modelValue: undefined,
  label: undefined,
  placeholder: undefined,
  hint: undefined,
  suffix: undefined,
  appendInnerIcon: undefined,
  prependInnerIcon: undefined,
  hideDetails: undefined,
  color: "primary",
  variant: undefined,
  loading: false, // component will break without this default
  disabled: undefined,
  readonly: undefined,
  persistentHint: undefined,
  persistentPlaceholder: undefined,
  counter: undefined,
  clearable: undefined,
  singleLine: undefined,
  autocomplete: undefined,
  rules: undefined,
  autofocus: undefined,
  errorMessage: undefined,
  density: undefined,
  inputmode: undefined,
  name: undefined,
  onKeydown: undefined,
  bgColor: undefined,
});
</script>

<template>
  <VTextField
    v-bind="{ ...attrs, ...props }"
    :errorMessages="props.errorMessage"
    @update:modelValue="
      (v) =>
        emit(
          'update:modelValue',
          v !== undefined && v !== null && v.toString().length ? v : ''
        )
    "
    @click:clear="emit('click:clear')"
    @click:appendInner="emit('click:appendInner')"
    @click:prependInner="emit('click:prependInner')"
    @update:focused="(v) => (v === false ? emit('blur') : emit('focused'))"
  >
    <template
      v-if="$slots['append-inner']"
      #append-inner
    >
      <slot name="append-inner" />
    </template>
    <template
      v-if="$slots['prepend-inner']"
      #prepend-inner
    >
      <slot name="prepend-inner" />
    </template>
    <slot />
  </VTextField>
</template>
